<template>
  <div class="exclusive">
    <!-- <AdvSwiper :advList="ATP_YcTurnList" class="adv" /> -->
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <van-row class="exclusive_row margintTop10">
      <van-col class="sidebar_col">
        <van-sidebar v-model="activeId" @change="onChange">
          <van-sidebar-item
            v-for="(page, index) in specialTagList"
            :title="page.name"
            :key="index"
          />
        </van-sidebar>
      </van-col>
      <div class="line"></div>
      <van-col span="24" class="sidebar_right">
        <CustomDetails :key="activeId" :id="id" />
      </van-col>
    </van-row>
  </div>
</template>
<script>
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { getSpecialTagList } from "@/utils/getClientPage.js";
import CustomDetails from "@/views/on_demand/exclusive/CustomTheme/custom_details.vue";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  components: {
    AdvSwiper,
    CustomDetails,
  },
  data() {
    return {
      activeId: "",
      id: "",
      ATP_YcTurnList: [],
      gridAdvList: [],
      specialTagList: [],
    };
  },
  created() {
    this.ATP_YcTurnList = getAdItem(AdType.ATP_YcTurn);
    this.gridAdvList = getAdItem(AdType.onDemandAdv).slice(0, 10);
    this.specialTagList = getSpecialTagList();
    if (this.specialTagList.length > 0) {
      this.id = this.specialTagList[0].id;
    }
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    onChange(index) {
      this.id = this.specialTagList[index].id;
    },
  },
};
</script>
<style lang="scss" scoped>
.margintTop10 {
  margin-top: 10px;
}
.exclusive {
  height: 100%;
  display: flex;
  flex-direction: column;
  .exclusive_row {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex: 1;
    .box1 {
      height: 100%;
      background: salmon;
    }
    .line {
      width: 1px;
      height: 100%;
      background: #888888;
      margin-right: 10px;
    }
    .sidebar_col {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      width: 82px;
      height: 100%;
      .van-sidebar {
        width: 70px;
      }
      .van-sidebar-item--select {
        color: $vermillion;
      }
      .van-sidebar-item {
        padding-top: 5px;
        padding-bottom: 5px;
        background: #fff;
        text-align: center;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}
.adv /deep/ .swiperSlide {
  height: 156.25px !important;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
