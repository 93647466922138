<template>
  <div class="exclusive">
    <!-- tab -->
    <van-tabs v-model="activeName" class="exclusive-tab" color="#f21313" title-inactive-color="#777777" title-active-color="#f21313" @change="change">
      <van-tab title="定制主题" name="custom" class="custom">
        <CustomTheme />
      </van-tab>
      <van-tab title="AV解说" name="avcomment" class="avcomment">
        <AvCommentary />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import AvCommentary from "@/views/on_demand/exclusive/AvCommentary/av_commentary.vue";
import CustomTheme from "@/views/on_demand/exclusive/CustomTheme/custom_theme.vue";
export default {
  name: "exclusive",
  components: {
    AvCommentary,
    CustomTheme,
  },
  data() {
    return {
      activeName: "custom",
    };
  },
  created() {
    if (this.$route.query.type == "av") {
      this.activeName = "avcomment";
    }
  },
  methods: {
    change() {
      this.$route.meta.sonPath = this.activeName;
    },
  },
};
</script>
<style lang="scss" scoped>
.exclusive {
  .custom {
    height: calc(100vh - 192px);
  }
  .avcomment {
    height: calc(100vh - 192px);
  }
  .exclusive-tab {
    /deep/ .van-tab {
      font-size: 16px;
      width: 90px;
      flex: unset;
    }
    /deep/ .van-tabs__line {
      bottom: 18px;
      width: 24px;
      height: 5px;
      border-radius: 12px;
    }
    /deep/ .van-tab--active {
      font-size: 18px;
    }
  }
}
</style>