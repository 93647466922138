import store from "@/store/index.js";

//获取点播tab
function getSpecialTagList() {
  let pageCfg = JSON.parse(store.state.app.appConfig).pageCfg
  if (pageCfg.length == 0) return [];
  return pageCfg.filter(page => {
    return (page.clientPage == 1 && page.tabMediaType == 0) || (page.clientPage == 1 && page.tabMediaType == 2)
  })

}

export {
  getSpecialTagList
}