<template>
  <div class="av_commentary">
    <Pullrefresh
      @onLoad="onLoad"
      :loading="loading"
      :finished="finished"
      @onRefresh="onRefresh"
      :refreshing="refreshing"
      :isNoData="isNoData"
      height="400px"
      :firstLoading="firstLoading"
    >
      <!-- <AdvSwiper :advList="ATP_YcTurnList" v-if="!refreshing" class="adv" /> -->
      <div class="advBox">
        <div
          class="advItem"
          v-for="(item, index) in gridAdvList"
          :key="index"
          @click="jumpAdd(item)"
        >
          <div class="advImgBox">
            <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
          </div>
          <div class="advTitle">{{ item.name }}</div>
        </div>
      </div>
      <div class="av-item" v-for="(item, index) in avList" :key="index">
        <h2>
          第<span class="av-item-date">{{ fomtPriod(item.period) }}</span
          >期&nbsp;&nbsp;{{ fomtDate(item) }}
        </h2>
        <div class="av-content" @click="jumpAv(item)">
          <ImgDecypt :src="item.cover" class="av-content-cover" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.desc }}</p>
          <div class="av_tag" v-show="item.tags">
            <span v-for="(tag, index) in item.tags" :key="index">
              {{ tag }}
            </span>
          </div>
          <CoveLabel class="cust_label" :item="item" :isMax="true" />
        </div>
        <AdvSwiper
          :advList="advList"
          v-if="(index + 1) % 4 == 0 && advList.length > 0"
          class="l_adv"
        />
      </div>
    </Pullrefresh>
  </div>
</template>
<script>
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { getAvdata } from "@/api/on_demand.js";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  components: {
    AdvSwiper,
    Pullrefresh,
    CoveLabel,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      ATP_YcTurnList: [],
      gridAdvList: [],
      req: {
        pageNum: 1,
        pageSize: 10,
      },
      avList: [],
      advList: [],
    };
  },
  created() {
    this.advList = getAdItem(AdType.columnAd);
    this.gridAdvList = getAdItem(AdType.onDemandAdv).slice(0, 10);
    this.ATP_YcTurnList = getAdItem(AdType.ATP_YcTurn);
    this.getData();
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    //获取数据
    async getData(type) {
      if (type != "refresh") {
        this.loading = true;
      }
      let ret = await this.$Api(getAvdata, this.req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if (ret.code == 200 && ret.data && ret.data.list != null) {
        let list = ret.data.list;
        this.avList = this.avList.concat(list);
        if (list.length == 0 && this.req.pageNum == 1) {
          this.isNoData = true;
        }
        if (list.length < this.req.pageSize) {
          this.finished = true;
        }
      }
    },
    //上滑加载
    onLoad() {
      this.req.pageNum++;
      this.getData();
    },
    //下拉刷新
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.req.pageNum = 1;
      this.avList = [];
      this.getData("refresh");
    },
    jumpAv(item) {
      this.$router.push({ path: "/avDetails", query: { id: item.id } });
    },
    //格式化日期
    fomtPriod(period) {
      let str = period;
      if (period < 10) {
        str = "0" + String(period);
      }
      return str;
    },
    fomtDate(data) {
      let createdAt = new Date(data.createdAt);
      let month = createdAt.getMonth() + 1;
      let day = createdAt.getDate();
      return month + "月" + day + "日";
    },
  },
};
</script>
<style lang="scss" scoped>
.av_commentary {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.av-item {
  margin: 10px;
  > h2 {
    text-align: center;
    font-size: 16px;
    color: #000;
    padding: 12px 0;
    .av-item-date {
      background-image: linear-gradient(to right, #ff0000, #f21212);
      color: #fff;
      font-size: 14px;
      margin: 0 2px;
      padding: 2px;
      display: inline-block;
      border-radius: 4px;
      font-weight: 400;
    }
  }
  .av-content {
    height: 100%;
    overflow: hidden;
    position: relative;
    h2 {
      font-size: 16px;
      color: #1d1b1c;
      margin: 10px 0;
      padding: 0 10px;
    }
    p {
      margin: 0 10px 10px 10px;
      font-size: 13px;
      text-align: left;
      color: #878887;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; //多行在这里修改数字即可
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
    }
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .av_tag {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        background-image: linear-gradient(to right, #ff0000, #f21212);
        color: #fff;
        display: inline-block;
        margin: 0 0 10px 10px;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 6px;
      }
    }
    .av-content-cover {
      border-radius: 6px 6px 0 0;
      overflow: hidden;
      height: 135px;
    }
  }
}

//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}
.l_adv {
  height: 100px;
  overflow: hidden;
  padding-top: 10px;
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    height: 100%;
  }
}
.adv /deep/ .swiperSlide {
  height: 156.25px !important;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
