<template>
  <div class="custom_details">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading" :isHigehtMax="true">
      <!-- <van-grid :column-num="2" :border="false" :gutter="10">
        <van-grid-item class="custom_item" v-for="(item,index) in mediaList" :key="index" >
          <div v-if="advList.length > 0 && item.adv" class="l_adv">
              <AdvSwiper :advList="item.adv" />
          </div>
          <div v-else @click="onJumpVideo(item)">
            <div class="cover-box">
              <ImgDecypt :src="item.videoCover" class="custom_item_img" />
              <div class="video-info flex-between">
                <div class="left">
                  <span class="left-play"></span>{{item.watchTimes|watchCount}}
                </div>
                <div class="right">{{item.playTime|videotime}}</div>
              </div>
            </div>
            <p>{{item.content}}</p>
            <CoveLabel class="cust_label" :item="item" />
          </div>
          
        </van-grid-item>
      </van-grid> -->
      <div class="custom_box">
        <div class="custom_item" v-for="(item,index) in mediaList" :key="index" >
          <div v-if="advList.length > 0 && item.adv" class="l_adv">
              <AdvSwiper :advList="item.adv" />
          </div>
          <div v-else @click="onJumpVideo(item)">
            <div class="cover-box">
              <ImgDecypt :src="item.videoCover" class="custom_item_img" />
              <div class="video-info flex-between">
                <div class="left">
                  <span class="left-play"></span>{{item.watchTimes|watchCount}}
                </div>
                <div class="right">{{item.playTime|videotime}}</div>
              </div>
            </div>
            <p>{{item.content}}</p>
            <CoveLabel class="cust_label" :item="item" />
          </div>
          
        </div>
      </div>
    </Pullrefresh>

  </div>
</template>
<script>
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getMediaList } from "@/api/on_demand.js";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { jumpVideo } from "@/utils/index.js";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  props: {
    id: {
      require: true,
      type: String,
    },
  },
  components: {
    Pullrefresh,
    CoveLabel,
    AdvSwiper,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      pageNum: 1,
      pageSize: 10,
      mediaList: [],
      oldMediaList: [],
      advList: [],
    };
  },
  created() {
    this.advList = getAdItem(AdType.content2x2);
    this.getData();
  },
  methods: {
    //上滑加载
    onLoad() {
      this.pageNum++;
      this.getData();
    },
    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.mediaList = [];
      this.getData("refresh");
    },
    //获取数据
    async getData(type) {
      if (type != "refresh") {
        this.loading = true;
      }
      if (this.pageNum == 1) {
        this.firstLoading = true;
      }
      let req = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        id: this.id,
        tags: [],
        isVip: false,
      };
      let ret = await this.$Api(getMediaList, req);

      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if (ret && ret.code == 200 && ret.data && ret.data.mediaInfos != null) {
        let mediaInfos = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        if (this.advList.length > 0) {
          this.oldMediaList = this.oldMediaList.concat(mediaInfos);
          // console.log(this.oldMediaList)
          let advNum = Math.floor(this.oldMediaList.length / 4);
          this.mediaList = JSON.parse(JSON.stringify(this.oldMediaList));
          let advLength = this.advList.length;
          for (let i = 0; i < advNum; i++) {
            let advItem = this.advList[i % advLength];
            this.mediaList.splice(5 * i + 4, 0, {
                adv: [advItem]
            });
          }
        } else {
            this.mediaList = this.mediaList.concat(mediaInfos);
        }
        // let mediaInfos = ret.data.mediaInfos;
        // this.oldMediaList = this.mediaList.concat(mediaInfos);
        // if (mediaInfos.length == 0 && this.pageNum == 1) {
        //   this.isNoData = true;
        //   return;
        // }
        // if (ret.data.mediaInfos.length < this.pageSize) {
        //   this.finished = true;
        // }
      }
    },
    onJumpVideo(item) {
      jumpVideo(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom_details {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  /deep/ .van-grid-item__content {
    padding: 0;
  }
  /deep/ .van-grid{
    padding-left: 0 !important;
  }
  .custom_item {
    width: 100%;
    border-radius: 6px 6px 0 0;
    position: relative;
    .cover-box {
      position: relative;
      .custom_item_img {
        width: 100%;
        height: 77px;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        /deep/ img {
          border-radius: 6px 6px 0 0;
        }
      }
    }
    p {
      width: 138px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      padding: 4px 0 0 0;
    }
  }
}
//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

//播放次数 时间
.video-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 18px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 10px;
  color: #ffffff;
  .left {
    display: flex;
    align-items: center;
    .left-play {
      display: inline-block;
      margin-right: 3px;
      width: 10px;
      height: 10px;
      background: url("../../../../assets/png/play_icon.png") center center
        no-repeat;
      background-size: 100%;
    }
  }
}
.l_adv {
  width: 100%;
  height: 96px;
  .adv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    width: 100%;
    height: 100%;
  }
  /deep/ .img_Decypt {
    width: 100%;
    height: 100%;
  }
  /deep/ .laz_com {
    width: 100%;
    height: 100%;
  }
}

.custom_box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 8px;
}
</style>
